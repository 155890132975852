// ==== THEME LOADER ==== //

// This is the main entry point for this theme and roughly follows these steps:
// 1) Initialize Modernizr and Google Analytics
// 2) Test for full browser support and load legacy polyfill bundle if needed
// 3) Activate Lazysizes immediately so it can get to work showing images
// 4) Lazy load the rest of the app as a separate bundle
// Note: the script URL and version are provisioned by Webpack's Define plugin

// A simple wrapper for Modernizr
import { browserSupport } from './polyfills/polyfills-browser-support'

// Initialize the application: activate Lazysizes (included in the loader bundle) and then lazy load the actual application bundle
const init = async () => {
	await import('./theme/image-loading' /* webpackChunkName: "imageLoading", webpackMode: "eager" */)
	await import('./theme/main-loader' /* webpackChunkName: "themeMain", webpackPreload: true */)
}

// Check for full browser support and load polyfill bundle before the application if any test fails
// Rationale: Webpack code splitting uses Promises; if we want them bundled separately we must use an external JS loader
// Note: `polyfillsUrl` is a global variable provisioned by the theme
if (browserSupport) {
	init()
} else if (window?.polyfillsUrl) {
	window.loadjs([window.polyfillsUrl], 'theme', {
		success: init,
	})
}
