// ==== BROWSER SUPPORT ==== //

// This module uses Modernizr to test for features specified in the webpack configuration file
import Modernizr from './polyfills-modernizr'

// If any of these tests fail (*e.g.* the Modernizr object does not return a truthy value for the named property) the polyfill bundle will be loaded
// Note 1: the name of the test must match *the property on the Modernizr object* and won't necessarily be that of the feature detect itself!
// Note 2: not all tests need to be included here
const browserTests = [
	'es6array',
	'es6object',
	'intersectionobserver',
	'objectfit',
	'promises',
	'srcset',
]

// Test for full browser support of all requested features
let browserTestsPassed = true

// For each test check whether the property exists on the Modernizr object and whether it's truthy
if (browserTests && Array.isArray(browserTests) && browserTests.length > 0) {
	for (const browserTest of browserTests) {
		if (Object.hasOwnProperty.call(Modernizr, browserTest)) {
			if (!Modernizr[browserTest]) {
				browserTestsPassed = false
			}
		} else {
			browserTestsPassed = false
		}
	}
}

// Export the result
export const browserSupport = browserTestsPassed
